//import stuff 
import React, { useState, useEffect, useRef } from 'react';
import defaultProfileImage from '../assets/images/default-profile-image.png';
import { FiArrowUp } from 'react-icons/fi';
import { MdShuffle } from 'react-icons/md';
import { AiFillDelete } from 'react-icons/ai';


const BulkTableRow = ({ comment, index, handleInputChange, removeComment, selectMenuItem, handleImageChange }) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Close dropdown if click is outside the menu and button
            if (isDropdownVisible && menuRef.current && !menuRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setIsDropdownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownVisible]);

    return (

        <>
            {/* Username */}
            <td className="px-2 py-2 whitespace-nowrap">
                <input
                    type="text"
                    id='username'
                    placeholder='Username'
                    value={comment.username}
                    onChange={(e) => handleInputChange(index, e)}
                    className="rounded-md border-0 py-1 px-1 text-gray-900 text-xs shadow-sm w-full ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset"

                />
            </td>

            {/* Comment */}
            <td className="px-2 py-2 whitespace-nowrap">
                <input
                    type="text"
                    id='comment'
                    placeholder='Comment'
                    value={comment.comment}
                    onChange={(e) => handleInputChange(index, e)}
                    className="rounded-md border-0 py-1 px-1 text-gray-900 shadow-sm text-xs w-full"
                />
            </td>

            {/* Verified Checkbox */}
            <td className="px-2 py-2 whitespace-nowrap text-center">
              

  <span className={`ml-2 cursor-pointer rounded-md px-1 py-0.5 text-xs hover:bg-opacity-75 ${comment.isVerified ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`} 
  onClick={() => {
    handleInputChange(index, { target: { id: 'isVerified', value: !comment.isVerified } });
  }}
  >
                                                    Verified
                                                </span>
            </td>

            {/* Profile Picture and Dropdown */}
            <td className="px-2 py-2 whitespace-nowrap text-center">
                <div className="flex items-center justify-center space-x-2"> {/* Adjust space-x-2 for spacing */}
                    {/* Profile Image */}
                    <img src={comment.imageSrc || defaultProfileImage} alt="Profile" className="h-5 w-5 object-cover rounded-full" />

                    {/* Buttons and Dropdown */}
                    <div className="relative inline-block text-left">
                    <div className="flex items-center space-x-1 px-1 py-2 border rounded-lg border-gray-100">
    {/* Retained original colors and reduced space between elements */}

    {/* Arrow Up Button */}
    <button
        type="button"
        className="rounded-md bg-gray-700 px-1 py-0.5 text-xs text-white shadow-sm hover:bg-gray-800"
        onClick={() => document.getElementById(`photo-${index}`).click()}
    >
        <FiArrowUp className="text-xxs" /> {/* Further reduced icon size */}
    </button>

    <span className="text-xs text-gray-500" style={{ fontSize: '10px' }}>OR</span>

    {/* Shuffle Button */}
    <button
        ref={buttonRef}
        type="button"
        className="rounded-md bg-gray-100 px-2 py-0.5 text-xs text-gray-600 hover:bg-gray-200"
        onClick={toggleDropdown}
    >
        <MdShuffle className="text-xxs" /> {/* Further reduced icon size */}
    </button>
</div>


                        {/* Dropdown Menu */}

                        <div className={`absolute z-10 mt-2 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition-transform duration-300 ease-in-out ${isDropdownVisible ? 'scale-y-100 opacity-100' : 'scale-y-0 opacity-0'}`} ref={menuRef} style={{ width: '100px' }}>
                            <div className="py-1">
                                {/* Menu Items */}
                                <button onClick={() => { selectMenuItem('male', index); toggleDropdown(); }} className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100">
                                    Male
                                </button>
                                <button onClick={() => { selectMenuItem('female', index); toggleDropdown(); }} className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100">
                                    Female
                                </button>
                                <button onClick={() => { selectMenuItem('celebrity', index); toggleDropdown(); }} className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100">
                                    Celebrity
                                </button>
                            </div>
                        </div >

                    </div>
                </div>
            </td>

            {/* Delete Button */}
            <td className="px-2 py-2 whitespace-nowrap text-center">
    <button
        type="button"
        className="inline-flex items-center justify-center rounded-full bg-neutral-100 px-2 py-1 text-sm font-medium text-neutral-600 hover:bg-neutral-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-500 transition ease-in-out duration-150"
        onClick={() => removeComment(index)}
        aria-label="Delete"
    >
        <AiFillDelete className="text-xs" />
    </button>
</td>

            <input
                className="hidden"
                type="file"
                id={`photo-${index}`}
                onChange={(e) => handleImageChange(index, e)}
                accept="image/*"
            />
        </>

    );
};

export default BulkTableRow;