import JSZip from 'jszip';
import domtoimage from 'dom-to-image';
import ReactDOMServer from 'react-dom/server';
import CommentBubble from '../components/CommentBubble';

function downloadAllCommentsAsZip(commentsInfo, setBulkDownloadLoading) {
    setBulkDownloadLoading(true);
    //wait 3 seconds 
  
    const zip = new JSZip();
    const imagePromises = [];

    commentsInfo.forEach((comment, index) => {
        const domNode = renderCommentToNode(comment);
        document.body.appendChild(domNode); // Temporarily add to the document

        const imagePromise = domtoimage.toPng(domNode, {
            width: domNode.clientWidth * 10,
            height: domNode.clientHeight * 10,
            style: {
                transform: 'scale(10)',
                transformOrigin: 'top left'
            }
        })
        .then(function (dataUrl) {
console.log({ dataUrl }); // Log to check the dataUrl
if (dataUrl.startsWith('data:image/png;base64,')) {
zip.file(`Comment_${index + 1}.png`, dataUrl.split('base64,')[1], { base64: true });
} else {
// Handle the error case where dataUrl is not as expected
console.error('Error in generating image:', dataUrl);
}
})
.catch(function (error) {
console.error('Error in converting DOM to Image:', error);
})
.finally(() => {
document.body.removeChild(domNode); // Clean up by removing the node

});

    imagePromises.push(imagePromise);
});

Promise.all(imagePromises).then(() => {
    zip.generateAsync({ type: "blob" }).then(content => {
        const link = document.createElement('a');
        link.download = "Comments.zip";
        link.href = URL.createObjectURL(content);
        link.click();
        URL.revokeObjectURL(link.href); // Clean up memory
    });
})
.finally(() => {
    setBulkDownloadLoading(false);
});
}
export default downloadAllCommentsAsZip;

function renderCommentToNode(comment) {
    // Create a React element for the comment
    const commentElement = (
        <div className="w-fit pb-5">
            <CommentBubble
                username={comment.username}
                isVerified={comment.isVerified}
                imageSrc={comment.imageSrc}
                comment={comment.comment}
            />
        </div>
    );

    // Convert the React element to HTML string
    const htmlString = ReactDOMServer.renderToStaticMarkup(commentElement);

    // Create a new DOM node and set its inner HTML to the generated string
    let domNode = document.createElement('div');
    //add this "classname w-fit pb-5"
    domNode.className = "w-fit pb-5";

    domNode.innerHTML = htmlString;
    return domNode;
}
