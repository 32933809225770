import React, { useState, useRef, useEffect } from 'react';
import downloadCommentImage from '../utilities/downloadCommentImage';
import defaultProfileImage from '../assets/images/default-profile-image.png'; // Adjust the import path as necessary
import canvas from '../assets/images/canvas.png';
import CommentBubble from '../components/CommentBubble';
import Footer from '../components/Footer';
import AboutSection from '../components/AboutSection';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import useClickOutside from '../components/useClickOutside';
import BulkTableRow from '../components/BulkTableRow';
//imùptoy AiOutlineFileExcel 
import { AiOutlineFileExcel } from 'react-icons/ai';
import { AiOutlinePlus } from 'react-icons/ai';
import * as XLSX from 'xlsx';
import downloadAllCommentsAsZip from '../utilities/downloadAllCommentsAsZip';
import verifiedIcon from '../assets/images/verified.svg';
import LogoWall from '../components/LogoWall';

//background: 'linear-gradient(135deg, rgb(0 147 255) 0%, rgb(238 29 210) 100%)', // TikTok-inspired gradient
function Home() {

    const initialCommentInfo = {
        username: '',
        comment: '',
        imageSrc: '',
        isVerified: false,
        nameCharacterCount: 0,
        commentCharacterCount: 0
    };
    const [commentsInfo, setCommentsInfo] = useState([initialCommentInfo, initialCommentInfo]);
    const [selectedCommentIndex, setSelectedCommentIndex] = useState(0);


    console.log(commentsInfo)


    const handleInputChange = (index, event) => {
        console.log('changing index ' + index)
        const { id, value, type, checked } = event.target;
        const updatedCommentsInfo = [...commentsInfo];
        updatedCommentsInfo[index] =
        {
            ...updatedCommentsInfo[index],
            [id]: type === 'checkbox' ? checked : value,
            ...(id === 'username' && { nameCharacterCount: value.length }),
            ...(id === 'comment' && { commentCharacterCount: value.length })
        };
        setCommentsInfo(updatedCommentsInfo);
    };

    const addComment = () => {
        setCommentsInfo([...commentsInfo, initialCommentInfo]);
    };

    const removeComment = (index) => {
        const updatedCommentsInfo = commentsInfo.filter((_, i) => i !== index);
        console.log({ updatedCommentsInfo })
        setSelectedCommentIndex(index - 1 >= 0 ? index - 1 : 0)
        setCommentsInfo(updatedCommentsInfo);
        //make setSelectedCommentIndex be the previous idnex of this one if it exists otherwise make it 0

    };

    const handleImageChange = (index, event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = e => {
            const updatedCommentsInfo = [...commentsInfo];
            updatedCommentsInfo[index] = {
                ...updatedCommentsInfo[index],
                imageSrc: e.target.result
            };
            setCommentsInfo(updatedCommentsInfo);
        };
        reader.readAsDataURL(file);
    };

    const [lastImages, setLastImages] = useState([]);

    const celebrityUsernames = {
        1: '@ArianaGrande',
        2: '@BarackObama',
        3: '@Beyonce',
        4: '@ElonMusk',
        5: '@JasonDerulo',
        6: '@JoeRogan',
        7: '@JustinBieber',
        8: '@KatyPerry',
        9: '@imKevinHart',
        10: '@KhloeKardashian',
        11: '@KimKardashian',
        12: '@KylieJenner',
        13: '@mrbeast',
        14: '@NickiMinaj',
        15: '@JoeBiden',
        16: '@SelenaGomez',
        17: '@TaylorSwift',
        18: '@TheRock',
        19: '@WillSmith',
        20: '@RealDonaldTrump',
        21: '@Rihanna'
    };
    

    function getRandomImage(category) {
        let maxImages = 5
        let extension = '.png'
        if(category === 'celebrity'){
            maxImages = 21
        } else if (category === 'male'){
            maxImages = 41
        } else if(category==='female'){
            maxImages = 25
        }
        let randomNumber;

        do {
            randomNumber = Math.floor(Math.random() * maxImages) + 1;
        } while (lastImages.includes(`${category}/${randomNumber+extension}`));

        const newLastImages = [`${category}/${randomNumber+extension}`, ...lastImages.slice(0, 2)];
        setLastImages(newLastImages);


        return { newImageSrc: require(`../assets/random-profile-images/${category}/${randomNumber+extension}`), newUsername: (category === 'celebrity') && celebrityUsernames[randomNumber] }
    }


    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const selectMenuItem = (category, index) => {
        // If no index is given then use the selectedCommentIndex
        let newIndex = index || selectedCommentIndex;
        setCommentsInfo(prev => prev.map((comment, i) => {
          let verified = comment.isVerified
          if(category === 'celebrity'){
              verified = true
          }
            if (i === newIndex) {
                let { newImageSrc, newUsername } = getRandomImage(category);
                return { ...comment, imageSrc: newImageSrc, username: newUsername ? newUsername : comment.username, isVerified: verified};
            }
            return comment;
        }));

        // Close the dropdown
        setIsDropdownVisible(false);
    };


    const menuRef = useRef(null);
    const menuButtonRef = useRef(null);

    useClickOutside([menuRef], () => setIsDropdownVisible(false));


    const [selectedTab, setSelectedTab] = useState("single");


    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        //clear the commentsInfo
        setCommentsInfo([])

        // This function handles the processing of the spreadsheet data
        const processSpreadsheet = (dataProp) => {
            const workbook = XLSX.read(dataProp, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

            const formattedData = data.map(item => {
                // Check and set default values for each column
                const username = item.username || '';
                const comment = item.comment || '';
                let imageSrc = item['Image URL (optional)'] || '';
                const isVerified = item['Verified?']?.toLowerCase() === 'yes';
                const isRandom = item['Random Image? (optional)']?.toLowerCase() === 'yes';
                const randomImageCategory = item['Random Image Category (optional)'] || 'male'; // Default to male if not specified

                // Assign a random image if 'isRandom' is true

                const { newImageSrc, newUsername } = getRandomImage(randomImageCategory);


                return {
                    username: isRandom ? newUsername : username,
                    comment: comment,
                    imageSrc: isRandom ? newImageSrc : imageSrc,
                    isVerified: isVerified,
                    nameCharacterCount: username.length,
                    commentCharacterCount: comment.length
                };
            });

            setCommentsInfo(formattedData);
        };

        reader.onload = (e) => {
            processSpreadsheet(e.target.result);
        };

        // Determine the type of the file and read accordingly
        const fileExt = file.name.split('.').pop().toLowerCase();
        if (fileExt === 'csv') {
            reader.readAsText(file); // For CSV files
        } else {
            reader.readAsBinaryString(file); // For XLSX, XLS, and other supported formats
        }

        reader.onload = (e) => {
            processSpreadsheet(e.target.result);
            // After processing, clear the file input
            event.target.value = '';
        };
    };

    const [bulkDownloadLoading, setBulkDownloadLoading] = useState(false);

    return (
        <div >

            <div className="background-gradient absolute z-0 top-0 bottom-0 left-0 right-0 " style={{ pointerEvents: 'none' }}
            >
                <img
                    src="https://assets-global.website-files.com/64d0ce443a2aa5bfebde64d2/64df5bc08ccc30bf928bdf4b_Background%20Blur.svg"
                    loading="eager"
                    alt=""

                    className=" w-full h-[130%] object-cover -mt-[250px] "
                //flip it horizontally

                />
            </div>
            <Header />

            <div className="bg-[#f7f8f9] flex flex-col text-black   HomepageContainer  z-10 " >
                <div className='py-16'  ></div>

                <HeroSection />

                <div className="flex  content-center lg:items-start items-center flex-col lg:flex-row mx-auto mb-8 gap-0" style={{ width: '90%', maxWidth: '900px' }}>




                    <div className='mb-4 mt-4 w-full lg:w-fit  z-40 '>

                        <div className={`md:sticky md:top-0 transition-all mx-auto   bg-white z-40  p-4 rounded-3xl  lg:rounded-r-none lg:w-[500px] w-full max-w-[490px] h-fit   lg:min-h-[735px] min-h-[535px] `} >
                            <div className="mb-2  p-3 rounded-md  z-40  ">
                                {/* <div className="flex border border-neutral-100 border-t-0 border-r-0 border-l-0 border-b-1 pb-6">
                                    <button
                                        className={`w-1/2 py-2 text-sm mr-2 font-medium rounded-md transition-all duration-300 ease-in-out ${selectedTab === "single" ? "bg-gradient-to-r from-blue-500 to-blue-600 text-white" : "text-gray-700 bg-gray-50 hover:bg-gray-100"}`}
                                        style={{ boxShadow: selectedTab === "single" ? 'inset 0 0 5px rgba(0,0,0,0.2)' : 'none' }}
                                        onClick={() => setSelectedTab("single")}
                                    >
                                        Single
                                    </button>

                                    <button
                                        className={`w-1/2 py-2 ml-2 text-sm font-medium rounded-md transition-all duration-300 ease-in-out ${selectedTab === "bulk" ? "bg-gradient-to-r from-green-400 to-blue-500 text-white" : "text-gray-700 bg-gray-50 hover:bg-gray-100"}`}
                                        style={{ boxShadow: selectedTab === "bulk" ? 'inset 0 0 5px rgba(0,0,0,0.2)' : 'none' }}
                                        onClick={() => setSelectedTab("bulk")}
                                    >
                                        Bulk
                                    </button>
                                </div> */}
                                <div className="relative flex flex-row items-center">
                                    <button
                                        className={`w-1/2 py-2 text-sm font-medium rounded-md outline-none border-none ring-0 transition-all duration-300 ease-in-out ${selectedTab === 'single' ? 'gradient-text-indigo-pink' : 'text-gray-700'} mr-2`}
                                        onClick={() => setSelectedTab('single')}
                                    >
                                        Single
                                    </button>
                                    <button
                                        className={`w-1/2 py-2 text-sm font-medium outline-none border-none ring-0 rounded-md transition-all duration-300 ease-in-out ${selectedTab === 'bulk' ? 'gradient-text-green-blue' : 'text-gray-700'}`}
                                        onClick={() => setSelectedTab('bulk')}
                                    >
                                        Bulk
                                    </button>
                                    <div className="absolute left-0 bottom-0 w-full h-0.5 bg-gray-100 rounded-t-full"></div>
                                    <div
                                        className={`absolute left-0 bottom-0 transition-all duration-200 ease-in-out w-1/2 h-0.5 rounded-t-full ${selectedTab === 'single' ? 'ml-0 bg-gradient-to-r from-indigo-500 to-pink-600' : 'ml-[50%] bg-gradient-to-r from-green-500 to-blue-500'}`}>
                                    </div>
                                </div>






                            </div>

                            {/* Comment Details Section */}
                            <div className="mt-4 overflow-x-none px-6 ">
                                {/* Single Comment Feature */}
                                {selectedTab === "single" &&
                                    <div className=" flex flex-col gap-y-10">
                                        {/* Profile Picture Upload */}
                                        <div>
                                            {/* <label className="block text-sm font-medium text-gray-900">Profile Picture</label> */}
                                            <div className="mt-2 flex flex-col items-start gap-x-3">
                                                <div className="flex items-center gap-x-3 ml-1">
                                                    <div className="flex h-12 w-12 min-w-12 min-h-12 items-center justify-center overflow-hidden rounded-full border border-gray-200">
                                                        <img src={commentsInfo[selectedCommentIndex]?.imageSrc || defaultProfileImage} alt="Profile" className="h-full w-full object-cover profile-image" />
                                                    </div>
                                                    <div className='flex items-center gap-x-3 p-4 py-2  rounded-lg border-gray-100'>
                                                        <button type="button" className="rounded-md bg-gray-700 px-3 py-1.5 text-sm text-white shadow-sm hover:bg-gray-800" onClick={() => document.getElementById('photo').click()}>
                                                            Upload
                                                        </button>
                                                        <span className="text-sm text-gray-500">OR</span>
                                                        {/* New Dropdown Button */}
                                                        <div className="relative inline-block text-left group">
                                                            <button

                                                                ref={menuButtonRef}
                                                                type="button"
                                                                className="rounded-md bg-gray-100 px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-200 inline-flex items-center"
                                                                onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                                                            >
                                                                Generate Random
                                                            </button>
                                                            <div className={`absolute z-10 mt-2 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition-transform duration-300 ease-in-out ${isDropdownVisible ? 'scale-y-100 opacity-100' : 'scale-y-0 opacity-0'}`}
                                                                ref={menuRef}
                                                                style={{ width: '100px' }}>
                                                                <div className="py-1" role="none">
                                                                    <button onClick={() => selectMenuItem('male')} className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100" role="menuitem">
                                                                        Male
                                                                    </button>
                                                                    <button onClick={() => selectMenuItem('female')} className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100" role="menuitem">
                                                                        Female
                                                                    </button>
                                                                    <button onClick={() => selectMenuItem('celebrity')} className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100" role="menuitem">
                                                                        Celebrity
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        {/* Merged Username Input and Verified Status */}
                                        <div>
                                            <div className="flex items-center">
                                                {/* <label htmlFor="username" className="block text-sm font-medium text-gray-900">Username</label> */}
                                                <span className={`ml-2 cursor-pointer rounded-md px-1 py-0.5 text-xs hover:bg-opacity-75 ${commentsInfo[selectedCommentIndex]?.isVerified ? 'bg-gray-200  text-gray-700' : 'bg-gray-200 text-gray-700'}`} onClick={() => setCommentsInfo(prev => prev.map((comment, index) => index === selectedCommentIndex ? { ...comment, isVerified: !comment.isVerified } : comment))}>
                                                    <div className="flex items-center">
                                                        <span>Verified</span>
                                                        <span
                                                            className={`bg-no-repeat bg-contain w-[1em] h-[1em] inline-block ml-2 ${!commentsInfo[selectedCommentIndex]?.isVerified ? 'grayscale' : ''
                                                                }`}
                                                            style={{ backgroundImage: `url(${verifiedIcon})` }}
                                                        ></span>
                                                    </div>


                                                </span>
                                            </div>
                                            <div className="mt-2 ml-1">
                                                <input
                                                    type="text"
                                                    id="username"
                                                    className="block  rounded-md border-0 py-1.5 px-2 w-[80%] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-none sm:text-sm"
                                                    placeholder="username"
                                                    value={commentsInfo[selectedCommentIndex]?.username}
                                                    onChange={(e) => handleInputChange(selectedCommentIndex, e)}
                                                    maxLength="24"
                                                />
                                                <label className="text-xs text-gray-400 ml-0.5">{commentsInfo[selectedCommentIndex]?.nameCharacterCount || 0}/24 characters</label>
                                            </div>
                                        </div>

                                        <div>
                                            {/* <label htmlFor="comment" className="block text-sm font-medium text-gray-900">Comment</label> */}
                                            <div className="ml-1 ">
                                                <textarea
                                                    id="comment"
                                                    rows="3"
                                                    className="block  rounded-md border-0 py-1.5 px-2 w-[80%] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-none sm:text-sm"
                                                    placeholder="Write your comment..."
                                                    value={commentsInfo[selectedCommentIndex]?.comment}
                                                    onChange={(e) => handleInputChange(selectedCommentIndex, e)}
                                                    maxLength="150"
                                                ></textarea>
                                                <label className="text-xs text-gray-400 mt-2 ml-0.5">{commentsInfo[selectedCommentIndex]?.commentCharacterCount}/150 characters</label>
                                            </div>
                                        </div>

                                        <div style={{ padding: '10px', overflow: 'hidden' }}> {/* Adjust padding as needed */}
                                            <button
                                                id="download"
                                                type="submit"
                                                style={{
                                                    background: 'linear-gradient(135deg, rgb(0 147 255) 0%, rgb(238 29 210) 100%)',
                                                }}
                                                className="py-3 text-sm rounded-full mt-6 w-full font-medium transition-all duration-300 ease-in-out bg-gradient-to-r from-indigo-500 to-pink-500 hover:from-indigo-600 hover:to-pink-600 text-white hover:scale-105"
                                                onClick={downloadCommentImage}
                                            >
                                                Download Comment
                                            </button>
                                        </div>




                                        {/* Hidden File Input */}
                                        <input
                                            className="hidden"
                                            type="file"
                                            id="photo"
                                            onChange={(e) => handleImageChange(selectedCommentIndex, e)}
                                            accept="image/*"
                                        />
                                    </div>}

                                {/* Bulk Comment Feature */}
                                {selectedTab === "bulk" &&
                                    <div className="mt-4  flex flex-col items-center gap-y-6">
                                        <table className="min-w-full   divide-y divide-gray-200 text-xs">
                                            <thead>
                                                <tr>
                                                    <th className="px-2 py-2 font-medium text-gray-400 uppercase tracking-wider text-center" style={{ width: '70px' }}>User</th>
                                                    <th className="px-2 py-2 font-medium text-gray-400 uppercase tracking-wider text-center" style={{ width: '100px' }}>Comment</th>
                                                    <th className="px-2 py-2 font-medium text-gray-400 uppercase tracking-wider text-center">Ver.</th>
                                                    <th className="px-2 py-2 font-medium text-gray-400 uppercase tracking-wider text-center">Pic</th>
                                                    <th className="px-2 py-2 font-medium text-gray-400 uppercase tracking-wider text-center"></th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {commentsInfo.map((comment, index) => (
                                                    <tr className='hover:bg-gray-50 transition cursor-pointer  focus:outline-none active:bg-gray-100  '
                                                        onClick={() => setSelectedCommentIndex(index)}
                                                    >

                                                        <BulkTableRow
                                                            key={index}
                                                            comment={comment}
                                                            index={index}
                                                            handleInputChange={handleInputChange}
                                                            removeComment={removeComment}
                                                            selectMenuItem={selectMenuItem}
                                                            useClickOutside={useClickOutside}
                                                            handleImageChange={handleImageChange}

                                                        />
                                                    </tr>
                                                ))}
                                            </tbody>

                                        </table>


                                        <div className=" flex items-start justify-center space-x-2 mt-8">
                                            {/* ADD Button */}
                                            <button
                                                className="rounded-md bg-gray-700 px-3 py-1.5 text-sm text-white shadow-sm hover:bg-gray-800 inline-flex items-center"
                                                onClick={addComment}
                                            >
                                                <AiOutlinePlus className="mr-2" /> New Row
                                            </button>

                                            {/* Upload Excel Button and Download Link */}
                                            <div className="flex flex-col items-center">
                                                <input
                                                    type="file"
                                                    id="fileInput"
                                                    style={{ display: 'none' }}
                                                    onChange={handleFileUpload}
                                                    accept=".xlsx, .xls , .csv, .ods"
                                                />
                                                <label
                                                    htmlFor="fileInput"
                                                    className="rounded-md bg-gray-100 px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-200 inline-flex items-center cursor-pointer"
                                                >
                                                    <AiOutlineFileExcel className="mr-2" /> Add in bulk
                                                </label>
                                                <a
                                                    href={`${process.env.PUBLIC_URL}/tokcomment_demo.xls`}
                                                    download="TokComment Demo.xls"
                                                    className="text-xs text-gray-500 opacity-90 w-24 mt-3 cursor-default"
                                                >
                                                    <span className="font-semibold cursor-pointer hover:text-gray-700 mt-1">Click here</span> to download demo excel
                                                </a>
                                            </div>
                                        </div>
                                        <div style={{ padding: '10px', overflow: 'hidden', width: '100%' }}> {/* Adjust padding as needed */}
                                            <button
                                                id="download"
                                                type="submit"
                                                disabled={bulkDownloadLoading}
                                                key={selectedTab}
                                                className={`download-btn mt-12  py-3 text-sm rounded-full font-medium transition-all w-full duration-300 ease-in-out ${bulkDownloadLoading ? 'opacity-50' : 'bg-gradient-to-r from-green-500 to-blue-500 text-white hover:scale-105'}`}
                                                onClick={() => {
                                                    downloadAllCommentsAsZip(commentsInfo, setBulkDownloadLoading)
                                                }}
                                            >
                                                {bulkDownloadLoading ? 'Loading...' : 'Download ALL Comments'}
                                            </button>
                                        </div>

                                    </div>
                                }

                            </div>
                        </div>
                    </div>

                    {/* Live Preview Section */}
                    <div className="mt-4 bg-white h-fit w-96 p-4 rounded-3xl lg:rounded-r-3xl lg:rounded-l-none z-20 "
                    //style={{backgroundImage:'linear-gradient(rgb(247 216 205 / 79%), rgb(204 214 255 / 79%) 68%)'}}
                    >
                        <div
                            className=" bg-cover rounded-md h-[44rem] pb-10 pt-24 px-10 mx-auto w-full"
                            style={{
                                //    backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,0) 75%, rgba(255,255,255,1) 100%), url(${canvas})`,
                                backgroundImage: `url(${canvas})`,
                                // backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,0) 75%, rgba(255,255,255,1) 100%), url(${canvas})`,

                            }}
                        >
                            <div id="fullResult" className="w-fit pb-5">
                                {/* Comment Bubble Component */}
                                <CommentBubble
                                    username={commentsInfo[selectedCommentIndex]?.username}
                                    isVerified={commentsInfo[selectedCommentIndex]?.isVerified}
                                    imageSrc={commentsInfo[selectedCommentIndex]?.imageSrc}
                                    comment={commentsInfo[selectedCommentIndex]?.comment}
                                />

                            </div>

                            {/* Positioned Button */}
                            {/* <button
                                id="download"
                                type="submit"
                                style={{
                                    background: 'linear-gradient(135deg, rgb(0 147 255) 0%, rgb(238 29 210) 100%)',
                                   // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)' // Custom shadow

                                    boxShadow: '0px 10px 15px 15px rgba(0, 0, 0, 0.9)' // Increased vertical offset and spread radius
                                }}
                                className="absolute bottom-7 left-1/2 transform -translate-x-1/2 w-[80%] py-3 text-sm rounded-full font-medium transition-all duration-300 ease-in-out bg-gradient-to-r from-indigo-500 to-pink-500 hover:from-indigo-600 hover:to-pink-600 text-white hover:scale-105"
                                onClick={downloadCommentImage}
                            >
                                Download Comment
                            </button> */}
                            {/* <button
                                id="download"
                                type="submit"
                                disabled={bulkDownloadLoading}

                                className={`absolute bottom-32 left-1/2 transform -translate-x-1/2 w-[80%] py-3 text-sm rounded-full font-medium transition-all duration-300 ease-in-out ${selectedTab === "bulk" ? 'scale-100 bg-gradient-to-r from-green-500 to-blue-500 text-white hover:scale-105 shadow-lg' : 'scale-0'} ${bulkDownloadLoading ? 'opacity-50' : ''}`}
                                onClick={() => {


                                    downloadAllCommentsAsZip(commentsInfo, setBulkDownloadLoading)
                                }}

                            >
                                {bulkDownloadLoading ? 'Loading...' : 'Download ALL Comments'}
                            </button> */}

                        </div>
                    </div>

                </div>
              
              <LogoWall />


                <AboutSection />


                <Footer />
            </div>
        </div>
    );
}

export default Home;
